import React, { useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import {
  TextInput,
  SelectBox,
  Check,
  DatePickerBox,
  DateTimePickerBox,
  RadioGroupBox,
  FileUploader,
  ToastEditor,
  FileMultipleUploader,
} from "../../components";
import { useForm } from "../../hooks";
import Handler from "../../Handler";
import API from "../../api";
import { SnackbarContext, DialogContext } from "../../contexts";
import { useHistory } from "react-router-dom";
import Util from "../../util";

export const AdminAddPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [
    formData,
    formMeta,
    formChange,
    setFormData,
    setFormMeta,
    formReset,
  ] = useForm(
    {
      type: "Admin",
      email: "",
    },
    {
      type: {
        key: "type",
        label: "구분",
        fullWidth: true,
        menus: [
          {
            value: "SuperAdmin",
            label: "SuperAdmin",
          },
          {
            value: "Admin",
            label: "Admin",
          },
        ],
      },
      email: {
        key: "email",
        label: "이메일",
        fullWidth: true,
      },
    }
  );

  const requsetAdd = () => {
    let body = Util.Form.formToBody(formData, formMeta);
    console.log("requsetAdd body", body);

    API.AdminUser.Post(body).then(
      (res) => {
        let result = res.data;
        console.log("result", result);
        updateSnackbar({
          type: "success",
          open: true,
          message: "이메일로 초기 비밀번호를 발송하였습니다.",
        });
        history.push("/admin/detail/" + result.no);
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  return (
    <article className="basic-page">
      <section className="content-header__container">
        <header>
          <h1>관리자 계정 등록</h1>
        </header>
        <div className="button__box">
          <Button variant="contained" onClick={requsetAdd}>
            등록
          </Button>
        </div>
      </section>
      <section className="form__container">
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className="flex">
          <SelectBox
            value={formData.type}
            onChange={formChange}
            meta={formMeta.type}
          ></SelectBox>
          <TextInput
            value={formData.email}
            onChange={formChange}
            meta={formMeta.email}
          ></TextInput>
        </div>
      </section>
    </article>
  );
};
