import moment from 'moment-timezone';

//new

const Test = () => {
  console.log('Test');
};

const REGEXP = {
  ALL20: '^.{0,20}$',
  PHONE: '^01[0-9]{8,9}$',
};

const formToBody = (formData, formMeta) => {
  console.log('formToBody formData', formData);
  console.log('formToBody formMeta', formMeta);
  return formData;
};

const filterToBody = (filterData) => {
  console.log('filterToBody formData', filterData);
  let body = {};
  for (let i in filterData) {
    let target = filterData[i];
    if (target.value) {
      body[target.key] = target.value;
    }
  }
  return body;
};

//old
const dbDateToJsDate = (date) => {
  //db date is setup by Asia/Seoul

  if (date) {
    let subString = date.replace('T', ' ').substring(0, 19);
    let YYYY = subString.substring(0, 4);
    let MM = subString.substring(5, 7);
    let DD = subString.substring(8, 10);
    let HH = subString.substring(11, 13);
    let mm = subString.substring(14, 16);
    let ss = subString.substring(17, 19);
    let returnDate = new Date(0);
    returnDate.setFullYear(YYYY);
    returnDate.setMonth(MM - 1);
    returnDate.setDate(DD);
    returnDate.setHours(HH);
    returnDate.setMinutes(mm);
    returnDate.setSeconds(ss);

    return returnDate;
  } else {
    return null;
  }
};

const dbDateToYYYYMMDDHHMMSS = (date) => {
  if (date) {
    return date.slice(0, 19).replace('T', ' ');
  } else {
    return null;
  }
};

const getYYYYMMDDByDate = (date) => {
  let temp_moment = moment(date).format('YYYYMMDD');
  return temp_moment;
};

const getDashedYYYYMMDDByDate = (date) => {
  let temp_moment = moment(date).format('YYYY-MM-DD');
  return temp_moment;
};

const Handler = {
  Test: Test,
  REGEXP: REGEXP,
  formToBody: formToBody,
  filterToBody: filterToBody,
};

export default Handler;
