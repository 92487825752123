import React, { useEffect, useState, useContext } from "react";
import { TableBox, SearchFilter, Paging } from "../../components";
import { usePagination, useFilter } from "../../hooks";
import Util from "../../util";
import API from "../../api";
import { SnackbarContext, DialogContext } from "../../contexts";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";

export const NoticeListPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState([
    {
      name: "name",
      description: "description",
    },
    {
      name: "name2",
      description: "description2",
    },
  ]);
  const [header, setHeader] = useState([
    {
      key: "no",
      align: "center",
      text: "No",
      style: {
        minWidth: 100,
      },
    },
    {
      key: "title",
      align: "center",
      text: "제목",
      style: {
        minWidth: 400,
      },
    },
    {
      key: "createdAt",
      align: "center",
      text: "등록일",
      render: (value) => {
        return Util.Form.getYYYYMMDDByUnix(value);
      },
    },
  ]);

  const [
    page,
    totalPage,
    totalCount,
    onPageChange,
    setPage,
    setTotalPage,
    setTotalCount,
  ] = usePagination(1, 10);

  const tableCallback = (type, index) => {
    console.log("type", type);
    console.log("index", index);
  };

  const tableRowClick = (index) => {
    console.log("tableRowClick index", index);
    let target_seq = list[index].no;
    console.log("tableRowClick target_seq", target_seq);
    history.push("/notice/detail/" + target_seq);
  };

  const onSearch = (body) => {
    console.log("onSearch", body);
  };

  useEffect(() => {
    console.log("page effect", page);
    if (page) {
      requestNewList();
    }
  }, [page]);

  const requestNewList = (init_page) => {
    console.log("requestNewList!");

    let params = {
      page: init_page ? init_page : page,
      limit: Util.Form.PAGE_LIMIT,
    };
    API.Notice.Get(params).then(
      (res) => {
        let result = res.data;
        console.log("result", result);
        setList(result.items);
        setTotalPage(result.meta.totalPages);
      },
      (err) => {
        console.log("err", err);
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  return (
    <article className="basic-page">
      <section className="content-header__container">
        <header>
          <h1>공지사항 목록</h1>
        </header>
        <div className="button__box">
          <Button
            variant="contained"
            onClick={() => {
              history.push("/notice/add");
            }}
          >
            등록
          </Button>
        </div>
      </section>
      <section className="table__container">
        <TableBox
          data={list}
          header={header}
          rowClick={tableRowClick}
          callback={tableCallback}
        ></TableBox>
      </section>
      <section className="paging__container">
        <Paging
          page={page}
          totalPage={totalPage}
          onChange={onPageChange}
        ></Paging>
      </section>
    </article>
  );
};
