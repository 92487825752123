import queryString from "query-string";
import moment from "moment-timezone";
import BigNumber from "bignumber.js";

const filterToBody = (filterData) => {
  console.log("filterToBody formData!", filterData);
  let body = {};
  for (let i in filterData) {
    let target = filterData[i];

    //REMOVE_ALL select box의 all 일 때는 빼줘야 하기 때문
    if (target.value && target.value !== "SELECT_ALL") {
      body[target.key] = target.value;
    }
  }
  return body;
};

const ObjectToParams = (obj) => {
  const serachString = queryString.stringify(obj);
  //default encodeURIComponent
  return "?" + serachString;
};

const getYYYYMMDDByUnix = (unix) => {
  //server에서 second로 떨어짐
  let temp_moment = moment(unix * 1000).format("YYYY.MM.DD");
  return temp_moment;
};

const getYYYYMMDDHHMMssByUnix = (unix) => {
  if (!unix) {
    return null;
  }
  //server에서 second로 떨어짐
  let temp_moment = moment(unix * 1000).format("YYYY.MM.DD HH:mm:ss");
  return temp_moment;
};

const formToBody = (formData, formMeta) => {
  console.log("formToBody formData", formData);
  console.log("formToBody formMeta", formMeta);
  return formData;
};

const NumberFormatter = (value, decimal) => {
  if (!value) {
    return "0";
  }
  if (Number(value) === 0) {
    return "0";
  }

  var parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (parts.length === 1) {
    return parts[0];
  }
  if (decimal) {
    let decimal_value = parts[1];
    console.log("decimal_value", decimal_value);
    if (decimal_value.length > decimal) {
      let new_decimal = decimal_value.slice(0, decimal);
      parts[1] = new_decimal;
    }
  }
  return parts.join(".");
};

const formatNumber = (number) => {
  // 숫자를 문자열로 변환
  let str = String(number);

  // 소수점 둘째자리까지 표현
  if (str.includes(".")) {
    const [integerPart, decimalPart] = str.split(".");
    if (decimalPart.length > 2) {
      str = `${integerPart}.${decimalPart.slice(0, 2)}`;
    }
  }

  // 1000단위로 콤마(,) 추가
  const regex = /\B(?=(\d{3})+(?!\d))/g;
  str = str.replace(regex, ",");

  return str;
};

const Form = {
  filterToBody: filterToBody,
  ObjectToParams: ObjectToParams,
  getYYYYMMDDByUnix: getYYYYMMDDByUnix,
  getYYYYMMDDHHMMssByUnix: getYYYYMMDDHHMMssByUnix,
  PAGE_LIMIT: 10,
  formToBody: formToBody,
  NumberFormatter: NumberFormatter,
  formatNumber: formatNumber,
};

export default Form;
