import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { NavigationContext, UserContext } from "../contexts";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _ from "lodash";

const NAV_LIST = [
  {
    id: "admin",
    text: "관리자 계정",
    child: [
      {
        text: "목록",
        path: "/admin/list",
      },
      {
        text: "추가",
        path: "/admin/add",
      },
    ],
  },
  {
    id: "member",
    text: "고객",
    child: [
      {
        text: "목록",
        path: "/member/list",
      },
    ],
  },
  {
    id: "calculate",
    text: "정산",
    child: [
      {
        text: "목록",
        path: "/calculate/list",
      },
    ],
  },
  {
    id: "notcie",
    text: "공지사항",
    child: [
      {
        text: "목록",
        path: "/notice/list",
      },
      {
        text: "등록",
        path: "/notice/add",
      },
    ],
  },
  {
    id: "membership",
    text: "유료게시판",
    child: [
      {
        text: "목록",
        path: "/membership/list",
      },
      {
        text: "등록",
        path: "/membership/add",
      },
    ],
  },
  {
    id: "board",
    text: "자유게시판",
    child: [
      {
        text: "목록",
        path: "/board/list",
      },
    ],
  },
  {
    id: "qna",
    text: "Q&A",
    child: [
      {
        text: "목록",
        path: "/qna/list",
      },
    ],
  },
  // {
  //   id: "query",
  //   text: "Query",
  //   child: [
  //     {
  //       text: "목록",
  //       path: "/query/list",
  //     },
  //   ],
  // },
  {
    id: "history",
    text: "관리자 이력",
    child: [
      {
        text: "목록",
        path: "/history/list",
      },
    ],
  },
  {
    id: "terms",
    text: "약관",
    child: [
      {
        text: "목록",
        path: "/terms/list",
      },
    ],
  },
  // {
  //   id: "1",
  //   text: "sample",
  //   path: "/sample/add",
  //   activeText: "sample",
  // },
  // {
  //   id: "2",
  //   text: "sample",
  //   child: [
  //     {
  //       text: "add",
  //       path: "/sample/add",
  //     },
  //     {
  //       text: "list",
  //       path: "/sample/list",
  //     },
  //   ],
  // },
];

export const Navigation = (props) => {
  const history = useHistory();
  const { navInfo, updateNavInfo } = useContext(NavigationContext);
  const { userInfo } = useContext(UserContext);

  const renderNavItem = (data, index) => {
    if (data.child) {
      return (
        <li className="accordion-item">
          <Accordion disableGutters={true} expanded={navInfo.expanded[index]}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#a6b0cf" }} />}
              onClick={() => {
                let new_navInfo = _.clone(navInfo);
                new_navInfo.expanded[index] = !new_navInfo.expanded[index];
                updateNavInfo(new_navInfo);
              }}
            >
              <span>{data.text}</span>
            </AccordionSummary>
            {data.child.map((item, child_index) => {
              let isActive = false;

              if (item.path === history.location.pathname) {
                isActive = true;
              }
              return (
                <AccordionDetails
                  className={isActive ? "active" : ""}
                  onClick={() => {
                    history.push(item.path);
                  }}
                >
                  <span>{item.text}</span>
                </AccordionDetails>
              );
            })}
          </Accordion>
        </li>
      );
    } else {
      let isActive = false;
      let startPathname = history.location.pathname.split("/")[1];

      if (startPathname === data.activeText) {
        isActive = true;
      }

      return (
        <li
          key={"main-nvaigation-" + index}
          className={isActive ? "active" : ""}
          onClick={() => {
            history.push(data.path);
          }}
        >
          <span>{data.text}</span>
        </li>
      );
    }
  };

  if (!userInfo) {
    return null;
  }
  return (
    <article className="navigation">
      <header>
        <h3
          onClick={() => {
            history.push("/");
          }}
        >
          Quant King
        </h3>
      </header>
      <ul>
        {NAV_LIST.map((item, index) => {
          if (userInfo.type !== "SuperAdmin" && index === 0) {
            return null;
          }
          return renderNavItem(item, index);
        })}
      </ul>
    </article>
  );
};
