import React, { useEffect, useState, useContext } from "react";
import {
  TextInput,
  SelectBox,
  Check,
  DatePickerBox,
  DateTimePickerBox,
  RadioGroupBox,
  FileUploader,
  ToastEditor,
  FileMultipleUploader,
  TextViewer,
  ToastViewer,
  FileMultipleViewer,
} from "../../components";
import { usePagination, useFilter, useForm } from "../../hooks";
import Util from "../../util";
import API from "../../api";
import { SnackbarContext, DialogContext } from "../../contexts";
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";

export const CalculateDetailPage = (props) => {
  const params = useParams();
  const { slug } = params;
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [data, setData] = useState(null);
  const [
    formData,
    formMeta,
    formChange,
    setFormData,
    setFormMeta,
    formReset,
  ] = useForm(
    {
      title: "",
      content: null,
      fileList: [],
    },
    {
      title: {
        key: "title",
        label: "제목",
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
      content: {
        key: "content",
        label: "내용",
        disabled: true,
      },
      fileList: {
        type: "file",
        key: "fileList",
        label: "파일 첨부",
        max: 10,
      },
      no: {
        label: "No",
        render: (value) => {
          return Util.Form.NumberFormatter(value);
        },
      },
      hit: {
        label: "조회수",
        render: (value) => {
          return Util.Form.NumberFormatter(value);
        },
      },
      createdAt: {
        label: "생성일",
        render: (value) => {
          return Util.Form.getYYYYMMDDHHMMssByUnix(value);
        },
      },
    }
  );
  const [memberData, setMemberData] = useState(null);
  const [calculateData, setCalculateData] = useState(null);

  const reuqsetNewData = () => {
    API.Calculate.GetDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log("result", result);
        setMemberData(result.member);
        setCalculateData(result.pointCalculate);
      },
      (err) => {
        console.log("err", err);
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };
  useEffect(() => {
    if (slug) {
      API.Calculate.GetDetail(slug).then(
        (res) => {
          let result = res.data;
          console.log("result", result);
          setMemberData(result.member);
          setCalculateData(result.pointCalculate);
        },
        (err) => {
          console.log("err", err);
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    }
  }, [slug]);

  const requsetComplete = () => {
    console.log("requsetDelete");
    const onDialogConfirm = () => {
      API.Calculate.PutComplete(slug).then(
        (res) => {
          updateSnackbar({
            type: "success",
            open: true,
            message: "완료 성공",
          });
          reuqsetNewData();
        },
        (err) => {
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    };

    updateDialog({
      type: "error",
      open: true,
      title: "정산 완료",
      description: "완료 후 되돌릴 수 없습니다. 진행하시겠습니까?",
      onConfirm: onDialogConfirm,
    });
  };

  const requsetDelete = () => {
    console.log("requsetDelete");
    const onDialogConfirm = () => {
      API.Calculate.PutReject(slug, { comment: "a" }).then(
        (res) => {
          updateSnackbar({
            type: "success",
            open: true,
            message: "반려 성공",
          });
          reuqsetNewData();
        },
        (err) => {
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    };

    updateDialog({
      type: "error",
      open: true,
      title: "정산 반려",
      description: "반려 후 되돌릴 수 없습니다. 진행하시겠습니까?",
      onConfirm: onDialogConfirm,
    });
  };

  return (
    <article className="basic-page">
      <section className="content-header__container">
        <header>
          <h1>정산 상세</h1>
        </header>
        <div className="button__box">
          {calculateData && calculateData.status === "Request" && (
            <Button
              variant="contained"
              color={"primary"}
              style={{ marginRight: 16 }}
              onClick={requsetComplete}
            >
              정산 완료
            </Button>
          )}
          {calculateData && calculateData.status === "Request" && (
            <Button
              variant="contained"
              color={"secondary"}
              onClick={requsetDelete}
            >
              반려
            </Button>
          )}
        </div>
      </section>
      <section className="form__container" style={{ marginBottom: 24 }}>
        <header>
          <h3>고객 정보</h3>
        </header>
        <div className="flex">
          {memberData && (
            <>
              <TextViewer
                value={memberData.id}
                meta={{
                  label: "아이디",
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={memberData.name}
                meta={{
                  label: "이름",
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={memberData.phone}
                meta={{
                  label: "연락처",
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={memberData.point}
                meta={{
                  label: "포인트",
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={memberData.lockPoint}
                meta={{
                  label: "정산 요청 포인트",
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={memberData.calculatePoint}
                meta={{
                  label: "정산 완료 포인트",
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </>
          )}
        </div>
      </section>
      <section className="form__container">
        <header>
          <h3>정산 정보</h3>
        </header>
        <div className="flex">
          {calculateData && (
            <>
              <TextViewer
                value={calculateData.status}
                meta={{
                  label: "처리 상태",
                  render: (value) => {
                    switch (value) {
                      case "Request": {
                        return "요청";
                      }
                      case "Complete": {
                        return "완료";
                      }
                      case "Reject": {
                        return "반려";
                      }
                      default: {
                        return "none";
                      }
                    }
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={calculateData.accountName}
                meta={{
                  label: "계좌주",
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={calculateData.bank}
                meta={{
                  label: "은행명",
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={calculateData.account}
                meta={{
                  label: "계좌번호",
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={calculateData.amount}
                meta={{
                  label: "포인트 요청 수량",
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
              <TextViewer
                value={calculateData.phone}
                meta={{
                  label: "연락처",
                  render: (value) => {
                    return value;
                  },
                }}
              ></TextViewer>
            </>
          )}
        </div>
      </section>
    </article>
  );
};
