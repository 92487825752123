import React, { useEffect, useState, useContext } from "react";
import {
  TextInput,
  SelectBox,
  Check,
  DatePickerBox,
  DateTimePickerBox,
  RadioGroupBox,
  FileUploader,
  ToastEditor,
  FileMultipleUploader,
  TextViewer,
} from "../../components";
import { usePagination, useFilter, useForm } from "../../hooks";
import Util from "../../util";
import API from "../../api";
import { SnackbarContext, DialogContext } from "../../contexts";
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";

export const AdminDetailPage = (props) => {
  const params = useParams();
  const { slug } = params;
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [data, setData] = useState(null);
  const [
    formData,
    formMeta,
    formChange,
    setFormData,
    setFormMeta,
    formReset,
  ] = useForm(
    {
      type: "Admin",
      email: "",
    },
    {
      type: {
        key: "type",
        label: "구분",
        fullWidth: true,
        menus: [
          {
            value: "SuperAdmin",
            label: "SuperAdmin",
          },
          {
            value: "Admin",
            label: "Admin",
          },
        ],
      },
      email: {
        key: "email",
        label: "이메일",
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
    }
  );

  useEffect(() => {
    if (slug) {
      API.AdminUser.GetDetail(slug).then(
        (res) => {
          let result = res.data;
          console.log("AdminUser.GetDetail ", result);
          setData(result);
          setFormData({ ...result });
        },
        (err) => {
          console.log("err", err);
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    }
  }, [slug]);

  const requestSave = () => {
    let body = Util.Form.formToBody(formData, formMeta);
    console.log("requestSave body", body);
    API.AdminUser.Put(slug, body).then(
      (res) => {
        updateSnackbar({
          type: "success",
          open: true,
          message: "변경 내용 저장",
        });
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  const requsetDelete = () => {
    console.log("requsetDelete");
    const onDialogConfirm = () => {
      API.AdminUser.Delete(slug).then(
        (res) => {
          updateSnackbar({
            type: "success",
            open: true,
            message: "삭제 성공",
          });
          history.push("/admin/list");
        },
        (err) => {
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    };

    updateDialog({
      type: "error",
      open: true,
      title: "관리자 계정 삭제",
      description: "삭제 후 되돌릴 수 없습니다. 진행하시겠습니까?",
      onConfirm: onDialogConfirm,
    });
  };

  const requestInitPassword = () => {
    const onDialogConfirm = () => {
      API.AdminUser.PutPassword(slug).then(
        (res) => {
          updateSnackbar({
            type: "success",
            open: true,
            message: "비밀번호 초기화 성공",
          });
        },
        (err) => {
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    };

    updateDialog({
      type: "info",
      open: true,
      title: "관리자 계정 비밀번호 초기화",
      description: "초기화 후 되돌릴 수 없습니다. 진행하시겠습니까?",
      onConfirm: onDialogConfirm,
    });
  };
  return (
    <article className="basic-page">
      <section className="content-header__container">
        <header>
          <h1>관리자 계정 상세</h1>
        </header>
        <div className="button__box">
          <Button
            variant="contained"
            color={"secondary"}
            style={{ marginRight: 16 }}
            onClick={requsetDelete}
          >
            삭제
          </Button>
          <Button
            variant="contained"
            color={"secondary"}
            style={{ marginRight: 16 }}
            onClick={requestInitPassword}
          >
            비밀번호 초기화
          </Button>
          <Button variant="contained" onClick={requestSave}>
            변경 내용 저장
          </Button>
        </div>
      </section>
      <section className="form__container">
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className="flex">
          <SelectBox
            value={formData.type}
            onChange={formChange}
            meta={formMeta.type}
          ></SelectBox>
          <TextViewer
            value={formData.email}
            onChange={formChange}
            meta={formMeta.email}
          ></TextViewer>
        </div>
      </section>
    </article>
  );
};
