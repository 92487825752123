import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";

export const TextArea = (props) => {
  const { value, onChange, meta, onEnter } = props;

  const handleChange = (e) => {
    onChange(e.target.value, meta.key);
  };

  const renderStyle = () => {
    if (meta.style) {
      return { ...meta.style };
    } else {
      return {};
    }
  };

  const checkError = () => {
    if (meta.regexp) {
      let targetRegexp = new RegExp(meta.regexp, "i");
      const isValidtion = targetRegexp.test(value);
      return !isValidtion;
    } else {
      return false;
    }
  };

  return (
    <article className="form-text-area">
      <header>
        <span>{meta.label}</span>
      </header>
      <textarea
        disabled={meta.disabled}
        required={meta.required}
        fullWidth={meta.fullWidth}
        value={value}
        onChange={handleChange}
        style={renderStyle()}
      ></textarea>
    </article>
  );
};
