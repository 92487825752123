import { useState } from 'react';
import _ from 'lodash';

export const useFilter = (initialData) => {
  const [filter, setFiler] = useState(initialData);
  // change
  const onChange = (new_value, index) => {
    console.log('[useFilter] new_value', new_value);
    console.log('[useFilter] index', index);
    let newFilter = _.cloneDeep(filter);
    const target = newFilter[index];
    console.log('target', target);
    target.value = new_value;
    setFiler(newFilter);
  };

  return [filter, onChange, setFiler];
};
