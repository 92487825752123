import { BASE_URL } from "../Config";
import AxiosInstance from "../AxiosInstance";
import Util from "../../util";

const PATH = "terms/";

const Get = (params) => {
  const url = BASE_URL + PATH + Util.Form.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetDetail = (slug) => {
  const url = BASE_URL + PATH + slug;
  return AxiosInstance.get(url);
};

const Put = (slug, body) => {
  const url = BASE_URL + PATH + slug;
  return AxiosInstance.put(url, body);
};

const Terms = {
  Get: Get,
  GetDetail: GetDetail,
  Put: Put,
};

export default Terms;
