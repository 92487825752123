import React, { useState, useEffect, useContext } from "react";
import { UserContext, SnackbarContext } from "../contexts";
import Util from "../util";
import API from "../api";

export const UserProvider = (props) => {
  const { children } = props;
  const [token, setToken] = useState(Util.Storage.GetItem("token"));
  const { updateSnackbar } = useContext(SnackbarContext);
  const [userInfo, setUserInfo] = useState(null);

  console.log("UserProvider - token", token);
  console.log("UserProvider - storage", Util.Storage.GetItem("token"));

  const clearToken = () => {
    Util.Storage.SetItem("token", "");
    setToken(null);
    setUserInfo(null);
  };

  const updateToken = (toeknValue) => {
    Util.Storage.SetItem("token", toeknValue);
    setToken(toeknValue);
  };

  useEffect(() => {
    console.log("UserProvider - token", token);

    if (token) {
      //token validation
      API.Admin.Get().then(
        (res) => {
          console.log("Member.Get()", res);
          setUserInfo(res.data);
        },
        (err) => {
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    } else {
      setUserInfo(null);
    }
  }, [token]);

  return (
    <UserContext.Provider
      value={{
        token: token,
        updateToken: updateToken,
        clearToken: clearToken,
        userInfo: userInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
