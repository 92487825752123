import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

export const TextInput = (props) => {
  const { value, onChange, meta, onEnter } = props;

  const handleChange = (e) => {
    onChange(e.target.value, meta.key);
  };

  const renderStyle = () => {
    if (meta.style) {
      return { ...meta.style };
    } else {
      return {};
    }
  };

  const checkError = () => {
    if (meta.regexp) {
      let targetRegexp = new RegExp(meta.regexp, 'i');
      const isValidtion = targetRegexp.test(value);
      return !isValidtion;
    } else {
      return false;
    }
  };

  return (
    <article className='form-text-input' style={renderStyle()}>
      <header>
        <span>{meta.label}</span>
      </header>
      <TextField
        required={meta.required}
        fullWidth={meta.fullWidth}
        label={meta.label}
        value={value}
        type={meta.type}
        disabled={meta.disabled}
        multiline={meta.multiline}
        error={checkError()}
        helperText={checkError() ? meta.helperText : ''}
        variant='outlined'
        onChange={handleChange}
        InputProps={meta.InputProps ? { ...meta.InputProps } : {}}
        onKeyPress={(e) => {
          console.log(`Pressed keyCode ${e.key}`);
          if (!meta.multiline && e.key === 'Enter') {
            // Do code here
            e.preventDefault();
            if (onEnter) {
              onEnter();
            }
          }
        }}
      ></TextField>
    </article>
  );
};
