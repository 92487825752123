import React, { useEffect, useState, useContext } from "react";
import {
  TextInput,
  SelectBox,
  Check,
  DatePickerBox,
  DateTimePickerBox,
  RadioGroupBox,
  FileUploader,
  ToastEditor,
  FileMultipleUploader,
  TextViewer,
  ToastViewer,
  FileMultipleViewer,
  TextArea,
} from "../../components";
import { usePagination, useFilter, useForm } from "../../hooks";
import Util from "../../util";
import API from "../../api";
import { SnackbarContext, DialogContext } from "../../contexts";
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";

export const TermsDetailPage = (props) => {
  const params = useParams();
  const { slug } = params;
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [data, setData] = useState(null);
  const [
    formData,
    formMeta,
    formChange,
    setFormData,
    setFormMeta,
    formReset,
  ] = useForm(
    {
      contents: null,
    },
    {
      contents: {
        key: "contents",
        label: "내용",
        style: {
          height: 860,
        },
      },
    }
  );

  useEffect(() => {
    if (slug) {
      API.Terms.GetDetail(slug).then(
        (res) => {
          let result = res.data;
          console.log("result", result);
          setData(result);
          setFormData({ ...result });
        },
        (err) => {
          console.log("err", err);
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    }
  }, [slug]);

  const requsetSave = () => {
    API.Terms.Put(slug, {
      contents: formData.contents,
    }).then(
      (res) => {
        let result = res.data;
        console.log("result", result);
        history.push("/terms/detail/" + result.no);
        updateSnackbar({
          type: "success",
          open: true,
          message: "약관 변경 완료",
        });
      },
      (err) => {
        console.log("err", err);
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  return (
    <article className="basic-page">
      <section className="content-header__container">
        <header>
          <h1>약관 상세</h1>
        </header>
        <div className="button__box">
          <Button variant="contained" onClick={requsetSave}>
            저장
          </Button>
        </div>
      </section>
      <section className="form__container">
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className="flex">
          <TextArea
            value={formData.contents}
            meta={formMeta.contents}
            onChange={formChange}
          ></TextArea>
          {/* <ToastEditor
            value={formData.contents}
            onChange={formChange}
            meta={formMeta.contents}
          ></ToastEditor> */}
        </div>
      </section>
    </article>
  );
};
