import { BASE_URL } from "../Config";
import AxiosInstance from "../AxiosInstance";
import Util from "../../util";

const PATH = "calculate/point/";

const Get = (params) => {
  const url = BASE_URL + PATH + Util.Form.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetDetail = (slug) => {
  const url = BASE_URL + PATH + slug;
  return AxiosInstance.get(url);
};

const PutComplete = (slug) => {
  const url = BASE_URL + PATH + slug + "/complete";
  return AxiosInstance.put(url);
};

const PutReject = (slug, body) => {
  const url = BASE_URL + PATH + slug + "/reject";
  return AxiosInstance.put(url, body);
};

const Calculate = {
  Get: Get,
  GetDetail: GetDetail,
  PutComplete: PutComplete,
  PutReject: PutReject,
};

export default Calculate;
