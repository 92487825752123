import React from "react";
import Routers from "./Routers";
import Provider from "./Provider";
import "./styles/index.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DialogLayer, SnackbarLayer } from "./layers";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4458b8",
    },
    secondary: {
      main: "#dc3545",
    },
    info: {
      main: "#777",
    },
    white: {
      main: "#ffffff",
      contrastText: "#60626a",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider>
        <DialogLayer></DialogLayer>
        <SnackbarLayer></SnackbarLayer>
        <Routers></Routers>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
