import React, { useContext } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import _ from 'lodash';

export const Check = (props) => {
  const { value, onChange, meta } = props;

  const handleChange = (e) => {
    onChange(e.target.checked, meta.key);
  };

  const handleChangeArray = (e, index) => {
    let new_value = _.clone(value);
    new_value[index] = e.target.checked;
    onChange(new_value, meta.key);
  };
  const renderStyle = () => {
    if (meta.style) {
      return { ...meta.style };
    } else {
      return {};
    }
  };

  if (Array.isArray(value)) {
    return (
      <article className='form-check-box' style={renderStyle()}>
        <header>
          <span>{meta.mainLabel}</span>
        </header>
        <div className='grid__box'>
          {value.map((item, index) => {
            return (
              <FormControlLabel
                disabled={meta.disabeld ? meta.disabeld[index] : false}
                control={
                  <Checkbox
                    onChange={(e) => {
                      handleChangeArray(e, index);
                    }}
                    checked={value[index]}
                  />
                }
                label={meta.label[index]}
              />
            );
          })}
        </div>
      </article>
    );
  } else {
    return (
      <article className='form-check-box' style={renderStyle()}>
        <header>
          <span>{meta.label}</span>
        </header>
        <div className='grid__box'>
          <FormControlLabel
            disabled={meta.disabled}
            control={<Checkbox onChange={handleChange} checked={value} />}
            label={meta.label}
          />
        </div>
      </article>
    );
  }
};
