import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { DialogContext, UserContext, SnackbarContext } from "../../contexts";
import { useForm } from "../../hooks";
import { TextInput } from "../../components";
import Button from "@mui/material/Button";

import API from "../../api";

export const UserLoginPage = (props) => {
  const history = useHistory();
  const { updateToken } = useContext(UserContext);
  const { updateDialog } = useContext(DialogContext);
  const { updateSnackbar } = useContext(SnackbarContext);

  const [formData, formMeta, formChange, setFormMeta, formReset] = useForm(
    {
      // id: "admin@mail.com",
      // pw: "admin",
      id: "",
      pw: "",
    },
    {
      id: {
        key: "id",
        label: "아이디",
        fullWidth: true,
      },
      pw: {
        type: "password",
        key: "pw",
        label: "비밀번호",
        fullWidth: true,
      },
    }
  );
  const onClickLogin = () => {
    console.log("formData", formData);
    //login process
    // updateToken("test");
    // history.push("/");
    // updateDialog({
    //   open: true,
    //   title: 'title',
    //   description: 'description',
    //   onConfirm: () => {
    //     console.log('work');
    //   },
    // });

    let body = {
      email: formData.id,
      password: formData.pw,
    };

    API.Admin.Login(body).then(
      (res) => {
        updateToken(res.data.token);
        history.push("/");
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  return (
    <article className="user-login-page">
      <header>
        <h1>관리자 로그인</h1>
      </header>
      <section className="form__box">
        <TextInput
          value={formData.id}
          onChange={formChange}
          meta={formMeta.id}
        ></TextInput>
        <TextInput
          value={formData.pw}
          onChange={formChange}
          meta={formMeta.pw}
          onEnter={onClickLogin}
        ></TextInput>
      </section>
      <section className="button__box">
        <Button
          size="large"
          style={{ width: "100%" }}
          variant="contained"
          onClick={onClickLogin}
        >
          로그인
        </Button>
      </section>
    </article>
  );
};
