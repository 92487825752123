import React, { useContext } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
export const Paging = (props) => {
  const { page, totalPage, onChange } = props;

  return (
    <article className='custom-paging'>
      <Stack spacing={4}>
        <Pagination
          showFirstButton
          showLastButton
          count={totalPage}
          color='primary'
          page={page}
          onChange={onChange}
          shape='rounded'
        />
      </Stack>
    </article>
  );
};
