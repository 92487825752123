import React, { useEffect, useState, useContext } from "react";
import { TableBox, SearchFilter, Paging } from "../../components";
import { usePagination, useFilter } from "../../hooks";
import Util from "../../util";
import API from "../../api";
import { SnackbarContext, DialogContext } from "../../contexts";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";

export const MemberListPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState([
    {
      name: "name",
      description: "description",
    },
    {
      name: "name2",
      description: "description2",
    },
  ]);
  const [header, setHeader] = useState([
    {
      key: "id",
      align: "center",
      text: "아이디(이메일)",
      style: {},
    },
    {
      key: "name",
      align: "center",
      text: "이름",
      style: {},
    },
    {
      key: "nick",
      align: "center",
      text: "닉네임",
      style: {},
    },
    {
      key: "phone",
      align: "center",
      text: "phone",
      style: {},
    },
    {
      key: "createdAt",
      align: "center",
      text: "등록일",
      render: (value) => {
        return Util.Form.getYYYYMMDDByUnix(value);
      },
    },
  ]);
  const [filter, onFilterChange, setFiler] = useFilter([
    {
      key: "id",
      type: "input",
      label: "아이디(이메일)",
      value: "",
      inputType: "text",
    },
    {
      key: "name",
      type: "input",
      label: "이름",
      value: "",
      inputType: "text",
    },
    {
      key: "nick",
      type: "input",
      label: "닉네임",
      value: "",
      inputType: "text",
    },
    {
      key: "recommendCode",
      type: "input",
      label: "추천인 코드",
      value: "",
      inputType: "text",
    },
  ]);

  const [
    page,
    totalPage,
    totalCount,
    onPageChange,
    setPage,
    setTotalPage,
    setTotalCount,
  ] = usePagination(1, 10);

  const tableCallback = (type, index) => {
    console.log("type", type);
    console.log("index", index);
  };

  const tableRowClick = (index) => {
    console.log("tableRowClick index", index);
    let target_seq = list[index].no;
    console.log("tableRowClick target_seq", target_seq);
    history.push("/member/detail/" + target_seq);
  };

  const onSearch = (body) => {
    console.log("onSearch", body);
    requestNewList(1);
  };

  useEffect(() => {
    console.log("page effect", page);
    if (page) {
      requestNewList();
    }
  }, [page]);

  const requestNewList = (init_page) => {
    console.log("requestNewList!");
    let filter_body = Util.Form.filterToBody(filter);
    console.log("requestNewList filter_body", filter_body);
    let params = {
      page: init_page ? init_page : page,
      limit: Util.Form.PAGE_LIMIT,
      ...filter_body,
    };

    API.Member.Get(params).then(
      (res) => {
        let result = res.data;
        console.log("result", result);
        setList(result.items);
        setTotalPage(result.meta.totalPages);
      },
      (err) => {
        console.log("err", err);
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  return (
    <article className="basic-page">
      <section className="content-header__container">
        <header>
          <h1>고객 목록</h1>
        </header>
      </section>
      <section className="filter__container">
        <SearchFilter
          data={filter}
          onChange={onFilterChange}
          onSearch={onSearch}
        ></SearchFilter>
      </section>
      <section className="table__container">
        <TableBox
          data={list}
          header={header}
          rowClick={tableRowClick}
          callback={tableCallback}
        ></TableBox>
      </section>
      <section className="paging__container">
        <Paging
          page={page}
          totalPage={totalPage}
          onChange={onPageChange}
        ></Paging>
      </section>
    </article>
  );
};
