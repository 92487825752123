import React, { useEffect, useState, useContext } from "react";
import {
  TextInput,
  SelectBox,
  Check,
  DatePickerBox,
  DateTimePickerBox,
  RadioGroupBox,
  FileUploader,
  ToastEditor,
  FileMultipleUploader,
  TextViewer,
  ToastViewer,
  FileMultipleViewer,
  TextArea,
} from "../../components";
import { usePagination, useFilter, useForm } from "../../hooks";
import Util from "../../util";
import API from "../../api";
import { SnackbarContext, DialogContext } from "../../contexts";
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";

export const QnaDetailPage = (props) => {
  const params = useParams();
  const { slug } = params;
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [data, setData] = useState(null);
  const [
    formData,
    formMeta,
    formChange,
    setFormData,
    setFormMeta,
    formReset,
  ] = useForm(
    {
      memberId: "",
      title: "",
      content: null,
      fileList: [],
    },
    {
      memberId: {
        key: "memberId",
        label: "작성자",
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
      title: {
        key: "title",
        label: "제목",
        fullWidth: true,
        render: (value) => {
          return value;
        },
      },
      content: {
        key: "content",
        label: "내용",
        disabled: true,
      },
      fileList: {
        type: "file",
        key: "fileList",
        label: "파일 첨부",
        max: 10,
      },
      no: {
        label: "No",
        render: (value) => {
          return Util.Form.NumberFormatter(value);
        },
      },
      createdAt: {
        label: "생성일",
        render: (value) => {
          return Util.Form.getYYYYMMDDHHMMssByUnix(value);
        },
      },
    }
  );

  const [answer, setAnswer] = useState("");

  useEffect(() => {
    if (slug) {
      API.Qna.GetDetail(slug).then(
        (res) => {
          let result = res.data;
          console.log("result", result);
          setData(result);
          setFormData({ ...result });
        },
        (err) => {
          console.log("err", err);
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    }
  }, [slug]);

  const reuqsetNewData = () => {
    API.Qna.GetDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log("result", result);
        setData(result);
        setFormData({ ...result });
      },
      (err) => {
        console.log("err", err);
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };
  const requsetDelete = () => {
    console.log("requsetDelete");
    const onDialogConfirm = () => {
      API.Qna.Delete(slug).then(
        (res) => {
          updateSnackbar({
            type: "success",
            open: true,
            message: "삭제 성공",
          });
          history.push("/qna/list");
        },
        (err) => {
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    };

    updateDialog({
      type: "error",
      open: true,
      title: "Q&A 삭제",
      description: "삭제 후 되돌릴 수 없습니다. 진행하시겠습니까?",
      onConfirm: onDialogConfirm,
    });
  };

  const requestAnswer = () => {
    const onDialogConfirm = () => {
      API.Qna.Put(slug, {
        answer: answer,
      }).then(
        (res) => {
          updateSnackbar({
            type: "success",
            open: true,
            message: "답변 등록 성공",
          });
          reuqsetNewData();
        },
        (err) => {
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    };

    updateDialog({
      type: "info",
      open: true,
      title: "답변 등록",
      description: "등록 후 되돌릴 수 없습니다. 진행하시겠습니까?",
      onConfirm: onDialogConfirm,
    });
  };
  return (
    <article className="basic-page">
      <section className="content-header__container">
        <header>
          <h1>Q&A 상세</h1>
        </header>
        <div className="button__box">
          <Button
            variant="contained"
            color={"secondary"}
            style={{ marginRight: 16 }}
            onClick={requsetDelete}
          >
            삭제
          </Button>
        </div>
      </section>
      <section className="form__container">
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className="flex">
          <TextViewer
            value={formData.memberId}
            meta={formMeta.memberId}
          ></TextViewer>

          <TextViewer value={formData.title} meta={formMeta.title}></TextViewer>
          <TextArea value={formData.content} meta={formMeta.content}></TextArea>
          <FileMultipleViewer
            value={formData.fileList}
            meta={formMeta.fileList}
          ></FileMultipleViewer>
          <TextViewer value={formData.no} meta={formMeta.no}></TextViewer>
          <TextViewer
            value={formData.createdAt}
            meta={formMeta.createdAt}
          ></TextViewer>
        </div>
      </section>

      <section className="form__container" style={{ marginTop: 40 }}>
        <header style={{ justifyContent: "space-between" }}>
          <h3>답변 정보</h3>
          <Button
            variant="contained"
            disabled={formData.answer}
            onClick={() => {
              requestAnswer();
            }}
          >
            답변 등록
          </Button>
        </header>
        {formData.answer && (
          <div className="flex">
            <TextArea
              value={formData.answer}
              meta={{
                disabled: true,
                label: "답변 내용",
              }}
            ></TextArea>
          </div>
        )}
        {!formData.answer && (
          <div className="flex">
            <TextArea
              value={answer}
              onChange={(new_value) => {
                setAnswer(new_value);
              }}
              meta={{
                label: "답변 내용",
              }}
            ></TextArea>
          </div>
        )}
      </section>
    </article>
  );
};
