import React, { useContext, useEffect, useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import ClearIcon from "@mui/icons-material/Clear";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import API from "../../api";
import { SnackbarContext } from "../../contexts";
import _ from "lodash";
import CircularProgress from "@mui/material/CircularProgress";

const acceptFIleList = (type) => {
  if (type === "image") {
    return "image/gif, image/jpeg, image/png, image/gif";
  } else if (type === "sound") {
    return "audio/mp3";
  } else if (type === "video") {
    return "video/mp4";
  } else {
    return "*";
  }
};

const renderPreview = (value, meta) => {
  const fileName = value ? value.split("/")[value.split("/").length - 1] : "";
  const decodedFilename = decodeURIComponent(fileName);

  if (!value) {
    return null;
  } else {
    if (meta.type === "image") {
      return (
        <div className="img__box">
          <img
            onClick={() => {
              window.open(value, "_blank");
            }}
            src={value}
          ></img>
        </div>
      );
    } else {
      return (
        <div className="file__box">
          <span
            onClick={() => {
              window.open(value, "_blank");
            }}
          >
            {decodedFilename}
          </span>
        </div>
      );
    }
  }
};

export const FileMultipleUploader = (props) => {
  const { value, onChange, meta } = props;
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { updateSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    if (file) {
      console.log("file", file);
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("path", meta.path);
      console.log("formData", formData);

      API.Common.PostFile(formData).then(
        (res) => {
          console.log("res", res);
          setLoading(false);
          let new_value = _.clone(value);
          new_value.push(res.data.url);
          onChange(new_value, meta.key);
          removeFileInput();
        },
        (err) => {
          console.log("err", err);
          setLoading(false);
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
          removeFileInput();
        }
      );
    }
  }, [file]);

  const removeFileInput = () => {
    document.getElementById(meta.key + "-file-uploader-input").value = "";
    setFile(null);
  };

  const removeFileS3 = (value, new_value) => {
    console.log("value", value);
    onChange(new_value, meta.key);
    removeFileInput();
    // let body = {
    //   url: value,
    // };
    // console.log('removeFile', body);

    // API.Common.DeleteFile(body).then(
    //   (res) => {
    //     console.log('file remove res', res);
    //     onChange(new_value, meta.key);
    //     removeFileInput();
    //   },
    //   (err) => {
    //     console.log('file remove err', err);
    //     onChange(new_value, meta.key);
    //     removeFileInput();
    //   },
    // );
  };

  const requestRemove = (index) => {
    console.log("requestRemove", index);

    let new_value = _.clone(value);
    var pulled = _.pullAt(new_value, index);

    console.log("requestRemove! new_value", new_value);
    console.log("requestRemove pulled", pulled);
    removeFileS3(pulled[0], new_value);
  };

  //value 항상 어레이로 유지.

  return (
    <article className="form-file-multiple-uploader">
      <header>
        <span>
          {meta.label}({value.length + "/" + meta.max}){" "}
          {meta.required ? "*" : ""}
        </span>
      </header>
      <ul className={`grid__box ${meta.type}`}>
        {value &&
          value.map((item, index) => {
            return (
              <li>
                {renderPreview(item, meta, index, requestRemove)}
                <div className="close__box">
                  <ClearIcon
                    onClick={() => {
                      requestRemove(index);
                    }}
                  ></ClearIcon>
                </div>
              </li>
            );
          })}
        {value && value.length < meta.max && (
          <li
            className="add"
            onClick={() => {
              document
                .getElementById(meta.key + "-file-uploader-input")
                .click();
            }}
          >
            {loading ? (
              <CircularProgress></CircularProgress>
            ) : (
              <AddCircleOutlineIcon fontSize="large"></AddCircleOutlineIcon>
            )}
          </li>
        )}
      </ul>
      <div className="input__box">
        <input
          type="file"
          accept={acceptFIleList(meta.type)}
          id={meta.key + "-file-uploader-input"}
          onChange={(e) => {
            setFile(e.target.files[0]);
          }}
        ></input>
      </div>
    </article>
  );
};
