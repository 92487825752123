import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { MainLayout, LoginLayout } from "./layouts";
import {
  HomePage,
  UserLoginPage,
  NotFoundPage,
  SampleAddPage,
  SampleListPage,
  SampleDetailPage,
  NoticeDetailPage,
  NoticeAddPage,
  NoticeListPage,
  MembershipAddPage,
  MembershipListPage,
  MembershipDetailPage,
  BoardListPage,
  BoardDetailPage,
  QnaListPage,
  QnaDetailPage,
  TermsListPage,
  TermsDetailPage,
  MypageDetailPage,
  AdminAddPage,
  AdminDetailPage,
  AdminListPage,
  MemberListPage,
  MemberDetailPage,
  QueryListPage,
  QueryDetailPage,
  HistoryDetailPage,
  HistoryListPage,
  CalculateDetailPage,
  CalculateListPage,
} from "./pages";
import { UserContext } from "./contexts";

const ROUTER_LIST = [
  {
    path: "/login",
    Layout: LoginLayout,
    Component: UserLoginPage,
  },

  {
    path: "/",
    Layout: MainLayout,
    Component: HomePage,
    protect: true,
    props: {
      exact: true,
    },
  },
  {
    path: "/mypage/detail",
    Layout: MainLayout,
    Component: MypageDetailPage,
  },
  {
    path: "/admin/add",
    Layout: MainLayout,
    Component: AdminAddPage,
  },
  {
    path: "/admin/list",
    Layout: MainLayout,
    Component: AdminListPage,
  },
  {
    path: "/admin/detail/:slug",
    Layout: MainLayout,
    Component: AdminDetailPage,
  },
  {
    path: "/member/list",
    Layout: MainLayout,
    Component: MemberListPage,
  },
  {
    path: "/member/detail/:slug",
    Layout: MainLayout,
    Component: MemberDetailPage,
  },
  {
    path: "/notice/add",
    Layout: MainLayout,
    Component: NoticeAddPage,
  },
  {
    path: "/notice/list",
    Layout: MainLayout,
    Component: NoticeListPage,
  },
  {
    path: "/notice/detail/:slug",
    Layout: MainLayout,
    Component: NoticeDetailPage,
  },
  {
    path: "/membership/add",
    Layout: MainLayout,
    Component: MembershipAddPage,
  },
  {
    path: "/membership/list",
    Layout: MainLayout,
    Component: MembershipListPage,
  },
  {
    path: "/membership/detail/:slug",
    Layout: MainLayout,
    Component: MembershipDetailPage,
  },
  {
    path: "/board/list",
    Layout: MainLayout,
    Component: BoardListPage,
  },
  {
    path: "/board/detail/:slug",
    Layout: MainLayout,
    Component: BoardDetailPage,
  },
  {
    path: "/qna/list",
    Layout: MainLayout,
    Component: QnaListPage,
  },
  {
    path: "/qna/detail/:slug",
    Layout: MainLayout,
    Component: QnaDetailPage,
  },
  {
    path: "/query/list",
    Layout: MainLayout,
    Component: QueryListPage,
  },
  {
    path: "/query/detail/:slug",
    Layout: MainLayout,
    Component: QueryDetailPage,
  },
  {
    path: "/history/list",
    Layout: MainLayout,
    Component: HistoryListPage,
  },
  {
    path: "/history/detail/:slug",
    Layout: MainLayout,
    Component: HistoryDetailPage,
  },
  {
    path: "/terms/list",
    Layout: MainLayout,
    Component: TermsListPage,
  },
  {
    path: "/terms/detail/:slug",
    Layout: MainLayout,
    Component: TermsDetailPage,
  },
  {
    path: "/calculate/list",
    Layout: MainLayout,
    Component: CalculateListPage,
  },
  {
    path: "/calculate/detail/:slug",
    Layout: MainLayout,
    Component: CalculateDetailPage,
  },
  {
    path: "/sample/add",
    Layout: MainLayout,
    Component: SampleAddPage,
  },
  {
    path: "/sample/list",
    Layout: MainLayout,
    Component: SampleListPage,
  },
  {
    path: "/sample/:id",
    Layout: MainLayout,
    Component: SampleDetailPage,
  },
  {
    path: "*",
    Layout: LoginLayout,
    Component: NotFoundPage,
  },
];

const Routers = () => {
  return (
    <Router>
      <Switch>
        {ROUTER_LIST.map((item, index) => {
          const { Layout, Component, path, protect, props } = item;
          if (protect) {
            return (
              <PrivateRoute key={"route-" + index} path={path} {...props}>
                <Layout>
                  <Component></Component>
                </Layout>
              </PrivateRoute>
            );
          } else {
            return (
              <Route key={"route-" + index} path={path} {...props}>
                <Layout>
                  <Component></Component>
                </Layout>
              </Route>
            );
          }
        })}
      </Switch>
    </Router>
  );
};

function PrivateRoute({ children, ...rest }) {
  const { token } = useContext(UserContext);
  let isValidation = false;
  if (token) {
    isValidation = true;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isValidation ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default Routers;
