import Admin from "./resources/Admin";
import Notice from "./resources/Notice";
import Common from "./resources/Common";
import Membership from "./resources/Membership";
import Board from "./resources/Board";
import Qna from "./resources/Qna";
import Terms from "./resources/Terms";
import AdminUser from "./resources/AdminUser";
import Member from "./resources/Member";
import Query from "./resources/Query";
import History from "./resources/History";
import Calculate from "./resources/Calculate";

const API = {
  Admin: Admin,
  Notice: Notice,
  Common: Common,
  Membership: Membership,
  Board: Board,
  Qna: Qna,
  Terms: Terms,
  AdminUser: AdminUser,
  Member: Member,
  Query: Query,
  History: History,
  Calculate: Calculate,
};

export default API;
