import React, { useEffect, useState, useContext } from "react";
import {
  TableBox,
  SearchFilter,
  Paging,
  TextViewer,
  TextInput,
} from "../../components";
import { usePagination, useFilter, useForm } from "../../hooks";
import Util from "../../util";
import API from "../../api";
import { SnackbarContext, DialogContext, UserContext } from "../../contexts";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";

export const MypageDetailPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { userInfo } = useContext(UserContext);
  const [
    formData,
    formMeta,
    formChange,
    setFormData,
    setFormMeta,
    formReset,
  ] = useForm(
    {
      oldPassword: "",
      newPassword: "",
      oldPasswordCheck: "",
    },
    {
      oldPassword: {
        key: "oldPassword",
        label: "기존 비밀번호",
        type: "password",
      },
      newPassword: {
        key: "newPassword",
        label: "새 비밀번호",
        type: "password",
      },
      newPasswordCheck: {
        key: "newPasswordCheck",
        label: "새 비밀번호 확인",
        type: "password",
      },
    }
  );
  if (!userInfo) {
    return null;
  }

  const requestPutPassword = () => {
    console.log("requestPutPassword");

    if (formData.newPassword !== formData.newPasswordCheck) {
      updateSnackbar({
        type: "error",
        open: true,
        message: "새 비밀번호를 확인해주세요.",
      });
      return;
    }

    API.Admin.PutPassword({
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
    }).then(
      (res) => {
        updateSnackbar({
          type: "success",
          open: true,
          message: "비밀번호가 변경되었습니다.",
        });
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  return (
    <article className="basic-page">
      <section className="content-header__container">
        <header>
          <h1>마이페이지</h1>
        </header>
      </section>
      <section className="form__container">
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className="flex">
          <TextViewer
            value={userInfo.type}
            meta={{
              label: "구분",
              fullWidth: true,
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
          <TextViewer
            value={userInfo.email}
            meta={{
              label: "이메일",
              fullWidth: true,
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
        </div>
      </section>

      <section className="form__container" style={{ marginTop: 24 }}>
        <header>
          <h3>비밀번호 변경</h3>
        </header>
        <div className="flex">
          <TextInput
            value={formData.oldPassword}
            onChange={formChange}
            meta={formMeta.oldPassword}
          ></TextInput>
          <TextInput
            value={formData.newPassword}
            onChange={formChange}
            meta={formMeta.newPassword}
          ></TextInput>
          <TextInput
            value={formData.newPasswordCheck}
            onChange={formChange}
            meta={formMeta.newPasswordCheck}
          ></TextInput>
          <Button
            style={{ marginTop: 24, width: 420 }}
            variant="contained"
            onClick={requestPutPassword}
          >
            비밀번호 변경하기
          </Button>
        </div>
      </section>
    </article>
  );
};
