import React, { useEffect, useState, useContext } from "react";
import {
  TextInput,
  SelectBox,
  Check,
  DatePickerBox,
  DateTimePickerBox,
  RadioGroupBox,
  FileUploader,
  ToastEditor,
  FileMultipleUploader,
  TextViewer,
  ToastViewer,
  FileMultipleViewer,
  TextArea,
} from "../../components";
import { usePagination, useFilter, useForm } from "../../hooks";
import Util from "../../util";
import API from "../../api";
import { SnackbarContext, DialogContext } from "../../contexts";
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ReactJson from "react-json-view";

export const HistoryDetailPage = (props) => {
  const params = useParams();
  const { slug } = params;
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [data, setData] = useState(null);
  const [
    formData,
    formMeta,
    formChange,
    setFormData,
    setFormMeta,
    formReset,
  ] = useForm(
    {
      createdAt: "",
      no: "",
      memberId: "",
      memberNo: "",
      body: "",
      type: "",
    },
    {
      no: {
        label: "No",
        render: (value) => {
          return Util.Form.NumberFormatter(value);
        },
      },
      memberNo: {
        label: "memberNo",
        render: (value) => {
          return value;
        },
      },
      memberId: {
        label: "memberId",
        render: (value) => {
          return value;
        },
      },
      body: {
        label: "body",
        render: (value) => {
          return value;
        },
      },
      type: {
        label: "type",
        render: (value) => {
          return value;
        },
      },
      createdAt: {
        label: "요청일",
        render: (value) => {
          return Util.Form.getYYYYMMDDHHMMssByUnix(value);
        },
      },
    }
  );

  useEffect(() => {
    if (slug) {
      API.History.GetDetail(slug).then(
        (res) => {
          let result = res.data;
          console.log("result", result);
          setData(result);
          setFormData({ ...result });
        },
        (err) => {
          console.log("err", err);
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    }
  }, [slug]);

  return (
    <article className="basic-page">
      <section className="content-header__container">
        <header>
          <h1>Query 상세</h1>
        </header>
      </section>
      <section className="form__container">
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className="flex">
          <TextViewer value={formData.no} meta={formMeta.no}></TextViewer>
          <TextViewer
            value={formData.memberNo}
            meta={formMeta.memberNo}
          ></TextViewer>
          <TextViewer
            value={formData.memberId}
            meta={formMeta.memberId}
          ></TextViewer>

          <TextViewer value={formData.type} meta={formMeta.type}></TextViewer>

          <article className="form-json-viewer">
            <header>
              <span>body</span>
            </header>

            <div className="json-viewer__box">
              <ReactJson src={formData.body} />
            </div>
          </article>

          <TextViewer
            value={formData.createdAt}
            meta={formMeta.createdAt}
          ></TextViewer>
        </div>
      </section>
    </article>
  );
};
