import { BASE_URL } from "../Config";
import AxiosInstance from "../AxiosInstance";

const Login = (body) => {
  const url = BASE_URL + "login";
  return AxiosInstance.put(url, body);
};

const Get = () => {
  const url = BASE_URL;
  return AxiosInstance.get(url);
};

const PutPassword = (body) => {
  const url = BASE_URL + "password";
  return AxiosInstance.put(url, body);
};
const Admin = {
  Login: Login,
  Get: Get,
  PutPassword: PutPassword,
};

export default Admin;
