import React, { useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import { DialogContext, SnackbarContext } from "../../contexts";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";

export const HomePage = (props) => {
  const { dialog, updateDialog } = useContext(DialogContext);
  const { updateSnackbar } = useContext(SnackbarContext);

  const onDialogConfirm = (e) => {
    console.log("onDialogConfirm");
  };

  return (
    <article className="basic-page">
      <span>Home</span>
    </article>
  );
};
