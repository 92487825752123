import React, { useContext } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const SelectBox = (props) => {
  const { value, onChange, meta, onEnter } = props;

  const handleChange = (e) => {
    onChange(e.target.value, meta.key);
  };

  const renderStyle = () => {
    if (meta.style) {
      return { ...meta.style };
    } else {
      return {};
    }
  };

  return (
    <article className='form-select-box' style={renderStyle()}>
      <header>
        <span>{meta.label}</span>
      </header>
      <FormControl fullWidth={meta.fullWidth}>
        <InputLabel>{meta.label}</InputLabel>
        <Select value={value} label={meta.label} onChange={handleChange}>
          {meta.menus.map((item, index) => {
            return <MenuItem value={item.value}>{item.label}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </article>
  );
};
