import React, { useEffect, useState, useContext } from "react";
import {
  TextInput,
  SelectBox,
  Check,
  DatePickerBox,
  DateTimePickerBox,
  RadioGroupBox,
  FileUploader,
  ToastEditor,
  FileMultipleUploader,
  TextViewer,
} from "../../components";
import { usePagination, useFilter, useForm } from "../../hooks";
import Util from "../../util";
import API from "../../api";
import { SnackbarContext, DialogContext } from "../../contexts";
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";

export const NoticeDetailPage = (props) => {
  const params = useParams();
  const { slug } = params;
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [data, setData] = useState(null);
  const [
    formData,
    formMeta,
    formChange,
    setFormData,
    setFormMeta,
    formReset,
  ] = useForm(
    {
      title: "",
      content: null,
      fileList: [],
    },
    {
      title: {
        key: "title",
        label: "제목",
        fullWidth: true,
      },
      content: {
        key: "content",
        label: "내용",
      },
      fileList: {
        type: "file",
        key: "fileList",
        label: "파일 첨부",
        path: "/faq/file",
        max: 10,
      },
      no: {
        label: "No",
        render: (value) => {
          return Util.Form.NumberFormatter(value);
        },
      },
      hit: {
        label: "조회수",
        render: (value) => {
          return Util.Form.NumberFormatter(value);
        },
      },
      createdAt: {
        label: "생성일",
        render: (value) => {
          return Util.Form.getYYYYMMDDHHMMssByUnix(value);
        },
      },
    }
  );

  useEffect(() => {
    if (slug) {
      API.Notice.GetDetail(slug).then(
        (res) => {
          let result = res.data;
          console.log("result", result);
          setData(result);
          setFormData({ ...result });
        },
        (err) => {
          console.log("err", err);
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    }
  }, [slug]);

  const requestSave = () => {
    let body = Util.Form.formToBody(formData, formMeta);
    console.log("requestSave body", body);
    API.Notice.Put(slug, body).then(
      (res) => {
        updateSnackbar({
          type: "success",
          open: true,
          message: "변경 내용 저장",
        });
      },
      (err) => {
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  const requsetDelete = () => {
    console.log("requsetDelete");
    const onDialogConfirm = () => {
      API.Notice.Delete(slug).then(
        (res) => {
          updateSnackbar({
            type: "success",
            open: true,
            message: "삭제 성공",
          });
          history.push("/notice/list");
        },
        (err) => {
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    };

    updateDialog({
      type: "error",
      open: true,
      title: "공지사항 삭제",
      description: "삭제 후 되돌릴 수 없습니다. 진행하시겠습니까?",
      onConfirm: onDialogConfirm,
    });
  };
  return (
    <article className="basic-page">
      <section className="content-header__container">
        <header>
          <h1>공지사항 상세</h1>
        </header>
        <div className="button__box">
          <Button
            variant="contained"
            color={"secondary"}
            style={{ marginRight: 16 }}
            onClick={requsetDelete}
          >
            삭제
          </Button>
          <Button variant="contained" onClick={requestSave}>
            변경 내용 저장
          </Button>
        </div>
      </section>
      <section className="form__container">
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className="flex">
          <TextInput
            value={formData.title}
            onChange={formChange}
            meta={formMeta.title}
          ></TextInput>
          <ToastEditor
            value={formData.content}
            onChange={formChange}
            meta={formMeta.content}
          ></ToastEditor>
          <FileMultipleUploader
            value={formData.fileList}
            onChange={formChange}
            meta={formMeta.fileList}
          ></FileMultipleUploader>
          <TextViewer value={formData.no} meta={formMeta.no}></TextViewer>
          <TextViewer value={formData.hit} meta={formMeta.hit}></TextViewer>
          <TextViewer
            value={formData.createdAt}
            meta={formMeta.createdAt}
          ></TextViewer>
        </div>
      </section>
    </article>
  );
};
