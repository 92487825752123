import React, { useContext } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Handler from '../../Handler';

export const SearchFilter = (props) => {
  const { data, onChange, onSearch } = props;

  const handleSelectChange = (e, index) => {
    onChange(e.target.value, index);
  };

  const handleInputChange = (e, index) => {
    onChange(e.target.value, index);
  };

  const handleDateChange = (new_value, index) => {
    onChange(new_value, index);
  };
  return (
    <article className='search-filter'>
      <header>
        <span>검색 필터</span>
        <div className='button__box'>
          <Button
            variant='contained'
            onClick={() => {
              let body = Handler.filterToBody(data);
              onSearch(body);
            }}
          >
            검색
          </Button>
        </div>
      </header>

      <ul>
        {data.map((item, index) => {
          const { type, value, label } = item;
          switch (type) {
            case 'select': {
              return (
                <li>
                  <header>
                    <span>{label}</span>
                  </header>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel>{label}</InputLabel>
                    <Select
                      value={value}
                      label={label}
                      onChange={(e) => {
                        handleSelectChange(e, index);
                      }}
                    >
                      {item.menus.map((menu_item, meni_index) => {
                        return <MenuItem value={menu_item.value}>{menu_item.label}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </li>
              );
            }
            case 'input': {
              return (
                <li>
                  <header>
                    <span>{label}</span>
                  </header>
                  <TextField
                    type={item.inputType}
                    value={value}
                    onChange={(e) => {
                      handleInputChange(e, index);
                    }}
                    fullWidth
                    label={label}
                    variant='outlined'
                  />
                </li>
              );
            }
            case 'date': {
              return (
                <li>
                  <header>
                    <span>{label}</span>
                  </header>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat={'yyyy-MM-dd'}
                        mask={'____-__-__'}
                        disableMaskedInput={true}
                        label={label}
                        value={value}
                        onChange={(e) => {
                          handleDateChange(e, index);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </li>
              );
            }
            case 'date-time': {
              return (
                <li>
                  <header>
                    <span>{label}</span>
                  </header>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        inputFormat={'yyyy-MM-dd HH:mm'}
                        mask={'____-__-__ __:__'}
                        disableMaskedInput={true}
                        label={label}
                        value={value}
                        onChange={(e) => {
                          handleDateChange(e, index);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </li>
              );
            }
            default: {
              return (
                <li>
                  <header>
                    <span>{label}</span>
                  </header>
                  <span>{value}</span>
                </li>
              );
            }
          }
        })}
      </ul>
    </article>
  );
};
