import React, { useEffect, useState } from 'react';
import { TableBox, SearchFilter, Paging } from '../../components';
import { usePagination, useFilter } from '../../hooks';

export const SampleListPage = (props) => {
  const [list, setList] = useState([
    {
      name: 'name',
      description: 'description',
    },
    {
      name: 'name2',
      description: 'description2',
    },
  ]);
  const [header, setHeader] = useState([
    {
      key: 'name',
      align: 'center',
      text: 'header 1',
      style: {
        minWidth: 200,
      },
    },
    {
      key: 'description',
      align: 'right',
      text: 'header 2',
      style: {
        minWidth: 400,
      },
    },
    {
      align: 'right',
      text: 'header 3',
      style: {
        minWidth: 300,
      },
      component: {
        type: 'button',
        label: 'cancel',
        callbackType: 'cancel',
      },
    },
  ]);
  const [filter, onFilterChange, setFiler] = useFilter([
    {
      key: 'select',
      type: 'select',
      label: 'select',
      value: 1,
      menus: [
        {
          value: 1,
          label: 'one',
        },
        {
          value: 2,
          label: 'two',
        },
      ],
    },
    {
      key: 'text',
      type: 'input',
      label: 'text',
      value: '',
      inputType: 'text',
    },
    {
      key: 'number',
      type: 'input',
      label: 'number',
      value: '',
      inputType: 'number',
    },
    {
      key: 'date',
      type: 'date',
      label: 'date',
      value: null,
    },
    {
      key: 'date-time',
      type: 'date-time',
      label: 'date-time',
      value: null,
    },
  ]);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(1, 10);

  const tableCallback = (type, index) => {
    console.log('type', type);
    console.log('index', index);
  };

  const onSearch = (body) => {
    console.log('onSearch', body);
  };

  useEffect(() => {
    console.log('page effect', page);
  }, [page]);

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h1>Sample List</h1>
        </header>
      </section>
      <section className='filter__container'>
        <SearchFilter data={filter} onChange={onFilterChange} onSearch={onSearch}></SearchFilter>
      </section>
      <section className='table__container'>
        <TableBox data={list} header={header} callback={tableCallback}></TableBox>
      </section>
      <section className='paging__container'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
