import React, { useContext, useEffect, useRef, useState } from "react";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Viewer } from "@toast-ui/react-editor";
import Handler from "../../Handler";
import API from "../../api";

export const FileMultipleViewer = (props) => {
  const { value, onChange, meta, onEnter } = props;

  // console.log('ToastViewer value', value);
  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.classNAme === "point") {
        return <div className="ico required"></div>;
      } else {
        return <span>*</span>;
      }
    }
  };

  const renderPreview = (value, meta) => {
    const fileName = value ? value.split("/")[value.split("/").length - 1] : "";

    if (!value) {
      return null;
    } else {
      if (meta.type === "image") {
        return (
          <div className="img__box">
            <img
              onClick={() => {
                window.open(value, "_blank");
              }}
              src={value}
            ></img>
          </div>
        );
      } else {
        return (
          <div className="file__box">
            <span
              onClick={() => {
                window.open(value, "_blank");
              }}
            >
              {fileName}
            </span>
          </div>
        );
      }
    }
  };

  const buildContainerClassname = () => {
    let classname = "form-file-multiple-viewer";

    return classname;
  };

  if (!value) {
    return null;
  }

  return (
    <article
      className={buildContainerClassname()}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>
            {meta.label}
            {renderRequired()}
          </span>
        </header>
      )}
      <ul className={`grid__box ${meta.type}`}>
        {value &&
          value.map((item, index) => {
            return <li>{renderPreview(item, meta, index)}</li>;
          })}
      </ul>
    </article>
  );
};
