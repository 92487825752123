import { BASE_URL } from "../Config";
import AxiosInstance from "../AxiosInstance";
import Util from "../../util";

const PATH = "board_free/";

const Get = (params) => {
  const url = BASE_URL + PATH + Util.Form.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const Post = (body) => {
  const url = BASE_URL + PATH;
  return AxiosInstance.post(url, body);
};

const GetDetail = (slug) => {
  const url = BASE_URL + PATH + slug;
  return AxiosInstance.get(url);
};

const Delete = (slug) => {
  const url = BASE_URL + PATH + slug;
  return AxiosInstance.delete(url);
};

const Board = {
  Get: Get,
  Post: Post,
  GetDetail: GetDetail,
  Delete: Delete,
};

export default Board;
