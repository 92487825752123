import React, { useContext, useRef, useEffect, useState } from "react";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import API from "../../api";

export const ToastEditor = (props) => {
  const { value, onChange, meta, onEnter } = props;
  const editorRef = useRef();
  const [initUpdate, setInitUpdate] = useState(true);

  const uploadImage = (file, path) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("path", meta.path);
    return API.Common.PostFile(formData);
  };

  useEffect(() => {
    if (editorRef && value && initUpdate && !meta.disabled && !meta.readOnly) {
      //초기 설정을 위한 셋업
      editorRef.current.getInstance().setHtml(value);
      setInitUpdate(false);
    }
  }, [editorRef, value]);

  return (
    <article className="form-toast-editor">
      <header>
        <span>{meta.label}</span>
      </header>
      <div className="editor__box">
        <Editor
          key={meta.id ? meta.id : "react-toast-editor"}
          viewer={true}
          height={meta.height ? meta.height : "560px"}
          initialEditType="wysiwyg"
          useCommandShortcut={true}
          onChange={() => {
            console.log("editorRef", editorRef);
            let new_value = editorRef.current.getInstance().getHtml();
            let remove_new_value = new_value.replaceAll("<br>", "");
            onChange(new_value, meta.key);
          }}
          ref={editorRef}
          hooks={{
            addImageBlobHook: async (blob, callback, lte, test) => {
              try {
                //editor에서 빈칸이 있으면 태그를 못만들기 때문에, 파일 이름의 빈칸을 없애야함.
                let file_name = "undifend.png";
                if (blob && blob.name) {
                  file_name = blob.name.replaceAll(" ", "_");
                }
                const file = new File([blob], file_name);
                const upload_result = await uploadImage(file, "editor");

                if (upload_result.data && upload_result.data.url) {
                  const file_url = upload_result.data.url;
                  callback(upload_result.data.url, file_name);
                } else {
                  return false;
                }
              } catch (e) {
                console.log("e", e);
                alert("upload fail");
                return false;
              }
            },
          }}
          toolbarItems={[
            "heading",
            "bold",
            "italic",
            "strike",
            "divider",
            "hr",
            "quote",
            "divider",
            "ol",
            "ul",
            "task",
            "divider",
            "table",
            "image",
            "link",
            "divider",
            "code",
            "codeblock",
          ]}
        />
      </div>
    </article>
  );
};
