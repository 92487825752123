import React, { useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import {
  TextInput,
  SelectBox,
  Check,
  DatePickerBox,
  DateTimePickerBox,
  RadioGroupBox,
  FileUploader,
  ToastEditor,
} from '../../components';
import { useForm } from '../../hooks';
import Handler from '../../Handler';

export const SampleAddPage = (props) => {
  const [formData, formMeta, formChange, setFormMeta, formReset] = useForm(
    {
      text: '',
      number: '',
      password: '',
      textArea: '',
      id: '',
      email: 'readonly@email.com',
      error: '',
      select: 1,
      check: true,
      checkArray: [false, false, false],
      date: null,
      dateTime: null,
      radio: 1,
      image: null,
      file: null,
      editor: null,
    },
    {
      text: {
        key: 'text',
        label: 'text',
        fullWidth: true,
      },
      number: {
        key: 'number',
        label: 'number',
        type: 'number',
        fullWidth: true,
        style: {},
      },
      password: {
        key: 'password',
        label: 'password',
        type: 'password',
        fullWidth: true,
      },
      textArea: {
        key: 'textArea',
        label: 'textArea',
        type: 'text',
        multiline: true,
        fullWidth: true,
      },
      id: {
        required: true,
        key: 'id',
        label: 'id',
        type: 'text',
        fullWidth: true,
      },
      email: {
        key: 'email',
        label: 'email readonly',
        type: 'text',
        fullWidth: true,
        InputProps: {
          readOnly: true,
        },
      },
      error: {
        key: 'error',
        label: '20자 error',
        type: 'text',
        fullWidth: true,
        regexp: Handler.REGEXP.ALL20,
        helperText: '20자 이내로 입력',
      },
      select: {
        key: 'select',
        label: 'select',
        fullWidth: true,
        menus: [
          {
            value: 1,
            label: 'one',
          },
          {
            value: 2,
            label: 'two',
          },
        ],
      },
      check: {
        key: 'check',
        label: 'check single',
      },
      checkArray: {
        key: 'checkArray',
        mainLabel: 'check multiple',
        label: ['check1', 'check2', 'check3'],
      },
      date: {
        key: 'date',
        label: 'date picker',
        fullWidth: true,
      },
      dateTime: {
        key: 'dateTime',
        label: 'date time picker',
        fullWidth: true,
      },
      radio: {
        key: 'radio',
        label: 'radio',
        menus: [
          {
            label: 'one',
            value: 1,
          },
          {
            label: 'two',
            value: 2,
          },
        ],
      },
      image: {
        type: 'image',
        key: 'image',
        label: 'image-single',
      },
      file: {
        type: 'file',
        key: 'file',
        label: 'file-single',
      },
      editor: {
        key: 'editor',
        label: 'editor sample',
      },
    },
  );

  const requsetAdd = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('requsetAdd body', body);
  };

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h1>Sample Add</h1>
        </header>
        <div className='button__box'>
          <Button variant='contained' onClick={requsetAdd}>
            Add
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <TextInput value={formData.text} onChange={formChange} meta={formMeta.text}></TextInput>
          <TextInput value={formData.number} onChange={formChange} meta={formMeta.number}></TextInput>
          <TextInput value={formData.password} onChange={formChange} meta={formMeta.password}></TextInput>
          <TextInput value={formData.textArea} onChange={formChange} meta={formMeta.textArea}></TextInput>
          <TextInput value={formData.id} onChange={formChange} meta={formMeta.id}></TextInput>
          <TextInput value={formData.email} onChange={formChange} meta={formMeta.email}></TextInput>
          <TextInput value={formData.error} onChange={formChange} meta={formMeta.error}></TextInput>
          <SelectBox value={formData.select} onChange={formChange} meta={formMeta.select}></SelectBox>
          <Check value={formData.check} onChange={formChange} meta={formMeta.check}></Check>
          <Check value={formData.checkArray} onChange={formChange} meta={formMeta.checkArray}></Check>
          <RadioGroupBox value={formData.radio} onChange={formChange} meta={formMeta.radio}></RadioGroupBox>
          <DatePickerBox value={formData.date} onChange={formChange} meta={formMeta.date}></DatePickerBox>
          <DateTimePickerBox
            value={formData.dateTime}
            onChange={formChange}
            meta={formMeta.dateTime}
          ></DateTimePickerBox>
          <FileUploader value={formData.image} onChange={formChange} meta={formMeta.image}></FileUploader>
          <FileUploader value={formData.file} onChange={formChange} meta={formMeta.file}></FileUploader>
          <ToastEditor value={formData.editor} onChange={formChange} meta={formMeta.editor}></ToastEditor>
        </div>
      </section>
    </article>
  );
};
