import { COMMON_URL } from "../Config";
import axios from "axios";

const PATH = "common/";

const PostFile = (formData) => {
  console.log("work");
  const url = COMMON_URL + "file";
  const headers = {
    "content-type": "multipart/form-data",
  };
  return axios.post(url, formData, headers);
};

const Common = {
  PostFile: PostFile,
};

export default Common;
