import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../contexts";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

export const MainHeader = (props) => {
  const history = useHistory();
  const { updateToken } = useContext(UserContext);

  return (
    <article className="main-header">
      <section className="option__box">
        <Button
          style={{ marginRight: 16 }}
          onClick={() => {
            history.push("/mypage/detail");
          }}
        >
          마이페이지
        </Button>
        <Button
          onClick={() => {
            updateToken("");
            history.push("/login");
          }}
        >
          로그아웃
        </Button>
      </section>
    </article>
  );
};
