import React, { useEffect, useState, useContext } from "react";
import {
  TextInput,
  SelectBox,
  Check,
  DatePickerBox,
  DateTimePickerBox,
  RadioGroupBox,
  FileUploader,
  ToastEditor,
  FileMultipleUploader,
  TextViewer,
  ToastViewer,
  FileMultipleViewer,
  TextArea,
} from "../../components";
import { usePagination, useFilter, useForm } from "../../hooks";
import Util from "../../util";
import API from "../../api";
import { SnackbarContext, DialogContext } from "../../contexts";
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ReactJson from "react-json-view";

export const QueryDetailPage = (props) => {
  const params = useParams();
  const { slug } = params;
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [data, setData] = useState(null);
  const [
    formData,
    formMeta,
    formChange,
    setFormData,
    setFormMeta,
    formReset,
  ] = useForm(
    {
      createdAt: "",
      no: "",
      query: "",
      requestIp: "",
      result: "",
      targetDB: "",
    },
    {
      no: {
        label: "No",
        render: (value) => {
          return Util.Form.NumberFormatter(value);
        },
      },
      query: {
        label: "Query",
        render: (value) => {
          return value;
        },
      },
      requestIp: {
        label: "Request IP",
        render: (value) => {
          return value;
        },
      },
      targetDB: {
        label: "Target DB",
        render: (value) => {
          return value;
        },
      },
      result: {
        label: "Result",
        render: (value) => {
          return value;
        },
      },
      createdAt: {
        label: "요청일",
        render: (value) => {
          return Util.Form.getYYYYMMDDHHMMssByUnix(value);
        },
      },
    }
  );

  useEffect(() => {
    if (slug) {
      API.Query.GetDetail(slug).then(
        (res) => {
          let result = res.data;
          console.log("result", result);
          setData(result);
          setFormData({ ...result });
        },
        (err) => {
          console.log("err", err);
          updateSnackbar({
            type: "error",
            open: true,
            message: err.data.message,
          });
        }
      );
    }
  }, [slug]);

  return (
    <article className="basic-page">
      <section className="content-header__container">
        <header>
          <h1>Query 상세</h1>
        </header>
      </section>
      <section className="form__container">
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className="flex">
          <TextViewer value={formData.no} meta={formMeta.no}></TextViewer>
          <TextViewer value={formData.query} meta={formMeta.query}></TextViewer>
          <article className="form-json-viewer">
            <header>
              <span>Result</span>
            </header>

            <div className="json-viewer__box">
              <ReactJson src={formData.result} />
            </div>
          </article>
          <TextViewer
            value={formData.requestIp}
            meta={formMeta.requestIp}
          ></TextViewer>
          <TextViewer
            value={formData.createdAt}
            meta={formMeta.createdAt}
          ></TextViewer>
        </div>
      </section>
    </article>
  );
};
