import React, { useEffect, useState, useContext } from "react";
import { TableBox, SearchFilter, Paging } from "../../components";
import { usePagination, useFilter } from "../../hooks";
import Util from "../../util";
import API from "../../api";
import { SnackbarContext, DialogContext } from "../../contexts";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";

export const CalculateListPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState([
    {
      name: "name",
      description: "description",
    },
    {
      name: "name2",
      description: "description2",
    },
  ]);
  const [header, setHeader] = useState([
    {
      key: "no",
      align: "center",
      text: "No",
      style: {
        minWidth: 100,
      },
    },
    {
      key: "status",
      align: "center",
      text: "처리 상태",
      style: {
        minWidth: 120,
      },
      render: (value) => {
        switch (value) {
          case "Request": {
            return "요청";
          }
          case "Complete": {
            return "완료";
          }
          case "Reject": {
            return "반려";
          }
          default: {
            return "none";
          }
        }
      },
    },
    {
      key: "accountName",
      align: "center",
      text: "계좌주",
      style: {
        minWidth: 100,
      },
    },
    {
      key: "bank",
      align: "center",
      text: "은행명",
      style: {
        minWidth: 100,
      },
    },
    {
      key: "account",
      align: "center",
      text: "계좌번호",
      style: {
        minWidth: 260,
      },
    },
    {
      key: "amount",
      align: "center",
      text: "포인트",
      style: {
        minWidth: 160,
      },
    },
    {
      key: "createdAt",
      align: "center",
      text: "등록일",
      render: (value) => {
        return Util.Form.getYYYYMMDDByUnix(value);
      },
      style: {
        minWidth: 120,
      },
    },
  ]);

  const [filter, onFilterChange, setFiler] = useFilter([
    {
      key: "status",
      type: "select",
      label: "처리 상태",
      value: "SELECT_ALL",
      menus: [
        {
          value: "SELECT_ALL",
          label: "전체",
        },
        {
          value: "Request",
          label: "요청",
        },
        {
          value: "Complete",
          label: "완료",
        },
        {
          value: "Reject",
          label: "반려",
        },
      ],
    },
  ]);

  const [
    page,
    totalPage,
    totalCount,
    onPageChange,
    setPage,
    setTotalPage,
    setTotalCount,
  ] = usePagination(1, 10);

  const tableCallback = (type, index) => {
    console.log("type", type);
    console.log("index", index);
  };

  const tableRowClick = (index) => {
    console.log("tableRowClick index", index);
    let target_seq = list[index].no;
    console.log("tableRowClick target_seq", target_seq);
    history.push("/calculate/detail/" + target_seq);
  };

  const onSearch = (body) => {
    console.log("onSearch", body);
    requestNewList();
  };

  useEffect(() => {
    console.log("page effect", page);
    if (page) {
      requestNewList();
    }
  }, [page]);

  const requestNewList = (init_page) => {
    console.log("requestNewList!");

    let filter_body = Util.Form.filterToBody(filter);
    console.log("requestNewList filter_body", filter_body);

    let params = {
      page: init_page ? init_page : page,
      limit: Util.Form.PAGE_LIMIT,
      ...filter_body,
    };
    API.Calculate.Get(params).then(
      (res) => {
        let result = res.data;
        console.log("result", result);
        setList(result.items);
        setTotalPage(result.meta.totalPages);
      },
      (err) => {
        console.log("err", err);
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  return (
    <article className="basic-page">
      <section className="content-header__container">
        <header>
          <h1>정산 목록</h1>
        </header>
      </section>

      <section className="filter__container">
        <SearchFilter
          data={filter}
          onChange={onFilterChange}
          onSearch={onSearch}
        ></SearchFilter>
      </section>

      <section className="table__container">
        <TableBox
          data={list}
          header={header}
          rowClick={tableRowClick}
          callback={tableCallback}
        ></TableBox>
      </section>
      <section className="paging__container">
        <Paging
          page={page}
          totalPage={totalPage}
          onChange={onPageChange}
        ></Paging>
      </section>
    </article>
  );
};
