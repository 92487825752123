import React, { useContext, useEffect, useState } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import ClearIcon from '@mui/icons-material/Clear';
const acceptFIleList = (type) => {
  if (type === 'image') {
    return 'image/gif, image/jpeg, image/png, image/gif';
  } else if (type === 'sound') {
    return 'audio/mp3';
  } else if (type === 'video') {
    return 'video/mp4';
  } else {
    return '*';
  }
};

const renderPreview = (value, meta, fileName) => {
  if (!value) {
    return null;
  } else {
    if (meta.type === 'image') {
      return <img src={value}></img>;
    } else {
      return <span>{fileName}</span>;
    }
  }
};

export const FileUploader = (props) => {
  const { value, onChange, meta } = props;
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);

  useEffect(() => {
    if (file) {
      console.log('file', file);

      // GET S3 URL
      const S3_url = 'https://www.gstatic.com/webp/gallery3/1.png';

      onChange(S3_url, meta.key);
      setFileName(file.name);
    } else {
      onChange(null, meta.key);
      setFileName(null);
    }
  }, [file]);

  return (
    <article className='form-file-uploader'>
      <header>
        <span>{meta.label}</span>
        <div className='ico__box'>
          <UploadIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              document.getElementById(meta.key + '-file-uploader-input').click();
            }}
          ></UploadIcon>
        </div>
      </header>
      {value && (
        <div className='preview__box'>
          <div className='ico__box'>
            <ClearIcon
              onClick={() => {
                document.getElementById(meta.key + '-file-uploader-input').value = '';
                setFile(null);
              }}
            ></ClearIcon>
          </div>
          <div className='img__box'>{renderPreview(value, meta, fileName)}</div>
        </div>
      )}
      <div className='input__box'>
        <input
          type='file'
          accept={acceptFIleList(meta.key)}
          id={meta.key + '-file-uploader-input'}
          onChange={(e) => {
            setFile(e.target.files[0]);
          }}
        ></input>
      </div>
    </article>
  );
};
