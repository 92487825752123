import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

export const LoginLayout = (props) => {
  const history = useHistory()
  const { children } = props

  return (
    <div className='login-layout'>
      <article className='content__container'>{children}</article>
    </div>
  )
}
