import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { MainHeader, Navigation } from '../components';

export const MainLayout = (props) => {
  const { children } = props;

  return (
    <div className='main-layout'>
      <section className='navigation__container'>
        <Navigation></Navigation>
      </section>
      <section className='content__container'>
        <section className='header__box'>
          <MainHeader></MainHeader>
        </section>
        <section className='content__box'>
          <article className='scroll__container'>{children}</article>
        </section>
      </section>
    </div>
  );
};
