import React, { useState, useEffect } from 'react';
import { NavigationContext } from '../contexts';

export const NavigationProvider = (props) => {
  const { children } = props;
  const [navInfo, setNavInfo] = useState({
    expanded: new Array(2).fill(false),
  });

  const updateNavInfo = (value) => {
    setNavInfo(value);
  };

  return (
    <NavigationContext.Provider
      value={{
        navInfo: navInfo,
        updateNavInfo: updateNavInfo,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
