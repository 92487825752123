import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import FormControl from '@mui/material/FormControl';

export const DateTimePickerBox = (props) => {
  const { value, onChange, meta, onEnter } = props;

  const handleChange = (new_value) => {
    console.log('handleChange', new_value);
    onChange(new_value, meta.key);
  };

  const renderStyle = () => {
    if (meta.style) {
      return { ...meta.style };
    } else {
      return {};
    }
  };

  return (
    <article className='form-date-time-picker-box' style={renderStyle()}>
      <header>
        <span>{meta.label}</span>
      </header>
      <FormControl fullWidth={meta.fullWidth}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            inputFormat={'yyyy-MM-dd HH:mm'}
            mask={'____-__-__ __:__'}
            disableMaskedInput={true}
            disabled={meta.disabled}
            readOnly={meta.readOnly}
            label={meta.label}
            value={value}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </FormControl>
    </article>
  );
};
